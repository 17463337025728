import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTaskDeptResponders,
  commonActions,
  getActiveUsers,
  taskActions,
  getDepartments,
} from "../../store";
import {
  DataTable,
  Menu,
  Button,
  Form,
  Modal,
  usePub,
  useSub,
} from "../../components";
import { Checkbox } from "antd";
import {
  CloseCircleOutlined,
  SwapOutlined,
  CheckCircleOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { AssignContrastIcon, TransferContrastIcon } from "../../components";

const Columns = [
  {
    title: "Department",
    dataIndex: "department_name",
    key: "department_name",
    width: "150px",
    className: "f14",
    headerClassName: "med",
  },
  {
    title: "Responder",
    dataIndex: "responder_name",
    key: "responder_name",
    width: "65%",
    className: "f14",
    headerClassName: "med",
    render: (text, record) => {
      // Check if the responder is disabled
      const isDisabled = record.responder && record.responder.is_disabled;
      return (
        <span style={{ color: isDisabled ? "red" : "inherit" }}>{text}</span>
      );
    },
  },
  { title: "", dataIndex: "action", key: "action", width: "64px" },
];

const ResponderFields = [
  {
    label: null,
    attribute: "responder",
    placeholder: "Type Responder Name...",
    type: "autocomplete",
    allowSearch: true,
    iconColor: "black",
  },
];

const ConfirmChangeModal = (props) => {
  const {
    open,
    selected_responder_details,
    old_responder,
    new_responder,
    onConfirm,
    onClose,
    isChange,
    associateResponder,
    removeResponder,
  } = props;

  React.useEffect(() => {
    if (open) {
      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          onClose();
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [open]);
  const handleConfirmTaskResponderChange = () => {
    if (selected_responder_details && new_responder) {
      const body = {
        task_dept_responders: {
          department_name: selected_responder_details.department_name,
          responder_id: new_responder.id,
          associate_task_responder: associateResponder,
          remove_previous_responder: removeResponder,
        },
      };
      onConfirm && onConfirm(body, isChange);
      onClose && onClose();
    }
  };
  return (
    <Modal
      width={480}
      open={open}
      closeIcon={null}
      className="change-res-modal"
      actions={[
        {
          label: (
            <>
              <CheckCircleOutlined className="mr1" />
              Confirm
            </>
          ),
          className: "gap   exo2 f14 bold-600",
          color: "#0133CC",
          variant: "lite",
          onClick: handleConfirmTaskResponderChange,
        },
        {
          label: (
            <>
              <CloseCircleOutlined className="mr1" />
              Cancel
            </>
          ),
          className: "gap bg0133CC exo2 f14 bold-600",
          color: "#FFF",
          onClick: onClose,
        },
      ]}
      title={
        <span className="exo2 f24 c238787">
          {isChange ? "Change" : "Assign"} Task Responder?
        </span>
      }
    >
      <div className="col w-100">
        {isChange ? (
          <div className="f16 reg c00085 desc">
            Responder for{" "}
            <b>
              {selected_responder_details &&
                selected_responder_details.department_name}
            </b>{" "}
            department will be changed!
            <p />
            <p>
              From: <b>{old_responder && old_responder}</b>
              <br />
              To: <b>{new_responder && new_responder.label}</b>
              <br />
            </p>
            <p>Both of them will be notified about this change.</p>
          </div>
        ) : (
          <div className="f16 reg c00085 desc">
            Confirm assignment of this Responder for the{" "}
            <b>
              {selected_responder_details &&
                selected_responder_details.department_name}
            </b>{" "}
            department.
            <br />
            <p>
              {" "}
              To: <b>{new_responder && new_responder.label}</b>
              <br />
            </p>
            The Responder will be notified about this assignment.
          </div>
        )}
      </div>
    </Modal>
  );
};

export const ChangeResponderModal = (props) => {
  const {
    open,
    selected_responder_details,
    onClose,
    associateResponder,
    setAssociateResponder,
    removeResponder,
    setRemoveResponder,
  } = props;
  const [state, setState] = React.useState({
    selectedUser: null,
    showConfirmDialog: false,
  });
  const users = useSelector(getActiveUsers);
  const isChange = Boolean(
    selected_responder_details && selected_responder_details.responder
  );
  React.useEffect(() => {
    if (!open) {
      // Reset state when modal closes
      setState((_) => ({ ..._, selectedUser: null }));
      setAssociateResponder(true);
      setRemoveResponder(false);
    }
    if (open) {
      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          onClose();
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [open, onClose]);
  const getUserOptions = () => {
    return users.map((_) => {
      return {
        label: `${_._listname}, ${_.designation}, ${_.department_name}`,
        id: _.id,
      };
    });
  };
  const handleSubmit = (e, value) => {
    if (e) e.preventDefault();
    setState((_) => ({
      ..._,
      showConfirmDialog: value !== undefined ? value : true,
    }));
  };
  const handleConfirmChange = (payload) => {
    props.onChange && props.onChange(payload, isChange);
    setState((_) => ({ ..._, showConfirmDialog: false, selectedUser: null }));
    onClose && onClose();
  };
  const handleResponderChange = (e) => {
    setState((_) => ({ ..._, selectedUser: e.target.value }));
  };
  const handleAssociateResponderChange = (e) => {
    const checked = e.target.checked;
    setAssociateResponder(checked);
  };
  const handleRemoveResponderChange = (e) => {
    const checked = e.target.checked;
    setRemoveResponder(checked);
  };
  const old_responder =
    selected_responder_details && selected_responder_details.responder
      ? `${selected_responder_details.responder_name} ${selected_responder_details.responder.designation} ${selected_responder_details.responder.department_name}`
      : "";
  return (
    <React.Fragment>
      <Modal
        width={480}
        open={open}
        closeIcon={null}
        className="change-res-modal"
        actions={[
          {
            label: (
              <>
                <CheckCircleOutlined className="mr1" />
                Confirm
              </>
            ),
            onClick: handleSubmit,
            className: "gap min  exo2 f14 bold-600",
            color: "#0133CC",
            variant: "lite",
            disabled: !Boolean(state.selectedUser),
          },
          {
            label: (
              <>
                <CloseCircleOutlined className="mr1" />
                Cancel
              </>
            ),
            className: "bg0133CC exo2 f14 bold-600",
            color: "#FFF",
            onClick: onClose,
          },
        ]}
        title={
          <span className="exo2 f24 c238787">
            {isChange ? "Change" : "Assign"} Task Responder?
          </span>
        }
      >
        <div className="col w-100">
          <div className="f16 reg c00085 desc">
            This Responder will become default for{" "}
            <b>
              {selected_responder_details &&
                selected_responder_details.department_name}
            </b>{" "}
            department.
            <br />
            All future Tasks will be automatically assigned to this individual.
            <br />
            {isChange && (
              <p className="f12">
                <Checkbox
                  checked={associateResponder}
                  onChange={handleAssociateResponderChange}
                />{" "}
                Associate this Task Responder with all currently existing Tasks?
                <br />
                <Checkbox
                  checked={removeResponder}
                  onChange={handleRemoveResponderChange}
                />{" "}
                <span className="cF00">
                  Remove previous Task Responder from all currently existing
                  Tasks?
                </span>
              </p>
            )}
          </div>
          <Form
            onSubmit={handleSubmit}
            className="row"
            formData={{ responder: state.selectedUser }}
            Fields={ResponderFields}
            getOptions={getUserOptions}
            onChange={handleResponderChange}
          />
        </div>
      </Modal>
      <ConfirmChangeModal
        selected_responder_details={selected_responder_details}
        onConfirm={handleConfirmChange}
        onClose={(e) => handleSubmit(e, false)}
        open={state.showConfirmDialog}
        old_responder={old_responder}
        new_responder={state.selectedUser}
        isChange={isChange}
        associateResponder={associateResponder}
        removeResponder={removeResponder}
      />
    </React.Fragment>
  );
};
const ResponderMenuContainer = ({ departmentResponder }) => {
  const publish = usePub();
  const { responder } = departmentResponder;
  const isResponderDefined = Boolean(responder);
  const Icon = isResponderDefined ? SwapOutlined : AssignContrastIcon;
  const handleSetDeptResponder = () => {
    publish("CHANGE_RESPONDER", departmentResponder);
  };
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleSetDeptResponder();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="col container f-rest">
      {Boolean(responder) ? (
        <div className="col user-info">
          <span className="f16 bold-600 c00085 line-22 caps">
            {responder._name}{" "}
            {responder.is_disabled && (
              <span className="deactv-usr">Deactivated User!</span>
            )}
          </span>
          <span className="f12 reg c00073">
            {responder.designation}, {responder.department_name}
          </span>
          <span className="f12 reg c0133CC line-22">{responder.email}</span>
          {!responder.is_disabled && (
            <span className="f12 reg line-22 c000">
              {responder.country_code_Num} {responder.mobile}
            </span>
          )}
        </div>
      ) : (
        <div className="user-info col w-100 f16 c00085">
          Default Responder not defined!
        </div>
      )}
      <div className="row h-end w-100 footer">
        <Button
          font="f14"
          className="bg0133CC p1 exo2 bold-600"
          color="#FFF"
          icon={
            <Icon
              style={{
                color: "#FFF",
                height: "16px",
                width: "16px",
                fontSize: "16px",
              }}
            />
          }
          onClick={handleSetDeptResponder}
          label={
            isResponderDefined ? (
              <div className="exo2 f14 bold-600">Change</div>
            ) : (
              <div className="exo2 f14 bold-600">Assign</div>
            )
          }
        />
      </div>
    </div>
  );
};

const MenuRender = (text, departmentResponder) => {
  const getMenus = () => {
    return [
      {
        key: "0",
        closeImmediately: true,
        label: (
          <ResponderMenuContainer departmentResponder={departmentResponder} />
        ),
      },
    ];
  };
  return (
    <Menu
      showArrow
      placement="bottomRight"
      classNames={{ overlay: "task-responders-menu" }}
      anchor={{ iconColor: "#1133CC" }}
      items={getMenus()}
    />
  );
};

export const ArcResponders = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    menuTarget: null,
    showChangeDialog: false,
    selectedDept: null,
  });
  const [associateResponder, setAssociateResponder] = useState(true);
  const [removeResponder, setRemoveResponder] = useState(false);
  const deptResponders = useSelector(getTaskDeptResponders) || [];
  const departments = useSelector(getDepartments) || [];

  useSub("CHANGE_RESPONDER", (deptResp) => {
    setState((_) => ({
      ..._,
      showChangeDialog: Boolean(deptResp),
      selectedDept: deptResp,
    }));
  });
  React.useEffect(() => {
    if (props.open) {
      dispatch(commonActions.fetchDepartments());
      dispatch(commonActions.fetchTaskDeptResponders());
      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          props.onClose();
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [props.open]);

  const getColumns = () => {
    let cols = Columns.map((_) => ({ ..._ }));
    cols[2].render = MenuRender;
    return cols;
  };
  const handleMenuClick = (e, record) => {
    setState((_) => ({
      ..._,
      menuTarget: e.currentTarget,
      selectedDept: record,
    }));
  };
  const handleSetDeptResponder = () => {
    setState((_) => ({ ..._, showChangeDialog: true, menuTarget: null }));
  };
  const handleChangeResponder = (body, isChangeResponder) => {
    const isChange = Boolean(isChangeResponder);
    dispatch(taskActions.updateDeptTaskResponder({ body, isChange }));
    setState((_) => ({ ..._, showChangeDialog: false }));
    setAssociateResponder(true);
    setRemoveResponder(false);
    props.onClose();
  };
  // Merge departments with responders
  const mergedDeptResponders = departments.map((dept) => {
    const dept_responder = deptResponders.find(
      (resp) => resp.department_name === dept.label
    );
    return {
      department_name: dept?.id,
      ...dept_responder,
    };
  });
  return (
    <React.Fragment>
      <Modal
        width={551}
        open={props.open}
        className="inc-res-modal"
        onClose={props.onClose}
        closeIcon={
          <span
            className="f14 bold-600 exo2 mr4 c0133CC d-flex"
            onClick={props.onClose}
          >
            <CloseCircleOutlined className="mr1" />
            Close
          </span>
        }
        title={<span className="exo2 f19 c238787">Task Responders</span>}
      >
        <div className="col w-100 responder-table" style={{ height: 230 }}>
          <DataTable
            noSelection
            Columns={getColumns()}
            rows={mergedDeptResponders}
          />
        </div>
      </Modal>
      <ChangeResponderModal
        selected_responder_details={state.selectedDept}
        open={state.showChangeDialog}
        onChange={handleChangeResponder}
        onClose={() => setState((_) => ({ ..._, showChangeDialog: false }))}
        associateResponder={associateResponder}
        removeResponder={removeResponder}
        setAssociateResponder={setAssociateResponder}
        setRemoveResponder={setRemoveResponder}
      />
    </React.Fragment>
  );
};
