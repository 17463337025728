import { put, takeEvery, select } from "redux-saga/effects";
import {
  SagaRegistry,
  requestHandler,
  axios,
  setAlertAndLoading,
  getDateInFormat,
  getUserDetails,
  getDateInFullYearIn24Format,
} from "../app";
import { taskActions } from "./slice";
import { getTaskProrityTypes, getTaskStatusTypes } from "../common";
import { getCurrentUser } from "../user";
import { userActions } from "../user";
import { saveAs } from "file-saver";

const TASK_PATH = "/tasks/";
const TASKS_PER_PAGE = 9;

function* getTask(_, expand = true) {
  const task = JSON.parse(JSON.stringify(_));
  getUserDetails(task, "creator");
  task.key = task.task_id;
  if (expand) {
    const priorities = yield select(getTaskProrityTypes);
    const statusList = yield select(getTaskStatusTypes);
    let status = statusList.find(({ id }) => id === task.status);
    if (status) task.status = status;
    let priority = priorities.find(({ id }) => id === task.priority);
    if (priority) task.priority = priority;
    // Ensure task.comments exists
    task.comments = Array.isArray(task.comments) ? [...task.comments] : [];

    let appendixID = 1; // Common appendix ID counter for all attachments

    // Process task.comments to add attachments and format creator
    if (Array.isArray(task.comments)) {
      task.comments = task.comments.map((comment) => {
        const formattedComment = { ...comment };

        // Process attachments
        formattedComment.attachments = Array.isArray(comment.attachments)
          ? comment.attachments.map((attachment) => {
              let formattedAttachment = { ...attachment };
              formattedAttachment.appendixID = `${
                appendixID < 10 ? "00" : appendixID < 100 ? "0" : ""
              }${appendixID}`;
              appendixID++;
              return formattedAttachment;
            })
          : [];

        getUserDetails(formattedComment, "creator");
        formattedComment.created_at = getDateInFullYearIn24Format(
          formattedComment.created_at
        );

        return formattedComment;
      });
    }

    // Extract evidence and comments from asmt_cntrl, if available
    if (task.asmt_cntrl) {
      // Extract and format evidence as comments
      if (Array.isArray(task.asmt_cntrl.evidence_list)) {
        const evidenceComments = task.asmt_cntrl.evidence_list.map(
          (evidence) => ({
            id: `evidence_${evidence.id}`,
            comment: `Evidence Uploaded: <a href="${evidence.evidence_url}" target="_blank">${evidence.evidence_file_name}</a>`,
            created_at: getDateInFullYearIn24Format(evidence.created_at),
            updated_at: evidence.updated_at,
            creator: evidence.creator || {}, // Use creator details if available
            attachments: [
              {
                id: evidence.id,
                document_file_name: evidence.evidence_file_name,
                appendixID: `${
                  appendixID < 10 ? "00" : appendixID < 100 ? "0" : ""
                }${appendixID}`,
                url: evidence.evidence_url,
              },
            ],
          })
        );

        appendixID += evidenceComments.length; // Increment for next batch
        task.comments = [...task.comments, ...evidenceComments];
      }

      // Extract and format comments from asmt_cntrl
      if (Array.isArray(task.asmt_cntrl.comment_list)) {
        const asmtComments = task.asmt_cntrl.comment_list.map((comment) => ({
          id: `asmt_comment_${comment.id}`,
          comment:
            comment.auditee_resp_comment ||
            comment.comment ||
            "No comment provided",
          created_at: getDateInFullYearIn24Format(comment.created_at),
          updated_at: comment.updated_at,
          creator: comment.creator || {}, // Use creator details if available
          attachments: [],
        }));

        task.comments = [...task.comments, ...asmtComments];
      }
    }

    // Sort comments by updated_at (descending order)
    task.comments.sort(
      (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
    );
    // Modify the external_links property to handle- plain text, multiple urls, title+url
    if (task.external_links) {
      const text = task.external_links.trim();
      const pattern = /(.*?)(https?:\/\/[^\s]+)/g;
      const matches = [];
      let match;

      while ((match = pattern.exec(text)) !== null) {
        const title = match[1].trim();
        const url = match[2].trim();
        if (title) {
          matches.push(`${title}\n${url}`);
        } else {
          matches.push(url);
        }
      }
      task.external_links = matches.length > 0 ? matches.join("\n\n") : text;
    }
  }
  // Check if task_responders exist, if not use assignee and supervisor
  if (
    !Array.isArray(task.task_responders) ||
    task.task_responders.length === 0
  ) {
    task.task_responders = [];

    // If assignee exists, add as main_responder
    if (task.assignee) {
      const mainResponder = { responder: { ...task.assignee } };
      getUserDetails(mainResponder.responder);
      task.main_responder = mainResponder.responder._listname;
      task.task_responders.push(mainResponder);
    }

    // If supervisor exists, add as another responder
    if (task.supervisor) {
      const supervisorResponder = { responder: { ...task.supervisor } };
      getUserDetails(supervisorResponder.responder);
      task.task_responders.push(supervisorResponder);
    }
  } else {
    // If responders exist, process them
    task.task_responders = task.task_responders.map((_) => {
      const responder = { ..._ };
      getUserDetails(responder.responder);
      return responder;
    });

    // Set the main responder to the first responder
    if (task.task_responders.length > 0) {
      task.main_responder = task.task_responders[0].responder?._listname;
    }
  }
  let title = task.asmt_cntrl
    ? `${task.asmt_cntrl.title}: ${task.asmt_cntrl.txt || ""}`
    : `${task.name}`;
  task.title = title;
  if (Number.isInteger(task.aging)) {
    task.aging = `${task.aging} d`;
  }
  if (!task.plan_date) {
    task.plan_date = task.due_date;
  }
  task.created_at = getDateInFormat(task.created_at);
  task.due_date_str = task.due_date ? getDateInFormat(task.due_date) : "";
  task.plan_date_str = task.plan_date ? getDateInFormat(task.plan_date) : "";
  task.closure_date_str = task.closure_date
    ? getDateInFormat(task.closure_date)
    : "";
  task.updated_at = getDateInFormat(task.updated_at);
  task.short_name = task.task_id + ": " + truncateName(task.name || "");
  return task;
}
const getDashboardFormat = (values) => {
  let overall = { department: "OVERALL" };
  let data = Object.keys(values).map((key) => {
    var _values = {};
    for (const _key in values[key]) {
      if (Object.hasOwnProperty.call(values[key], _key)) {
        let val = values[key][_key],
          valKey = _key.replaceAll(" ", "");
        if (!overall[valKey]) overall[valKey] = 0;
        _values[valKey] = val;
        overall[valKey] += Number(val);
      }
    }
    return {
      department: key,
      ..._values,
    };
  });
  return [overall, ...data];
};
const getSourceTasksMap = (values) => {
  return Object.keys(values).map((key) => {
    let asmt;
    try {
      asmt = JSON.parse(key);
    } catch (error) {
      asmt = [key];
    }
    const id = asmt[1] || asmt[0],
      label = asmt[0] || asmt[1],
      list = values[key];
    let rows = [];
    const overall = Object.keys(list).reduce((initial, value, index, arrrr) => {
      rows.push({ label: value, ...list[value] });
      return initial + list[value].total;
    }, 0);
    return {
      id: id,
      label: label,
      rows: rows,
      overall: overall,
    };
  });
};
function* setConfirmationYield(action) {
  yield put(taskActions.setConfirmation(action.payload));
}
function* getApiKey({ payload }) {
  yield requestHandler(function* () {
    const guid = payload.guid;
    let res = yield axios.get(TASK_PATH + "get_api_key?guid=" + guid);
    if (res.api_key) {
      localStorage.setItem("NM-API-Key", res.api_key);
      localStorage.setItem("KEY_NM_GUID", guid);
      yield put(userActions.validateToken());
    }
  });
}

function* fetchDefinedList({ payload }) {
  yield requestHandler(function* () {
    let type = payload.type;
    const mapType = type === "custom_type_list" ? "activity_type_list" : type;
    let path = TASK_PATH + type;
    if (type === "mapped_orgs") {
      path = "/consoles/" + type;
    }
    let res = yield axios.get(path);
    let list = res[mapType] ? res[mapType] : Array.isArray(res) ? res : [];
    list = list.map((_) => {
      if (Array.isArray(_)) {
        return { label: _[0], id: _[1], guid: _[2] };
      } else if (typeof _ === "object") {
        if (!_.label) {
          _.label = Boolean(_.firstname)
            ? `${_.firstname} ${_.lastname}`
            : _.title || "";
        }
        return _;
      } else {
        return { label: _, id: _ };
      }
    });
    yield put(taskActions.fetchDefinedListSuccess({ [type]: list }));
  });
}

function* fetchMappedOrgs() {
  yield requestHandler(function* () {
    const type = "mapped_orgs";
    let res = yield axios.get("/consoles/" + type);
    let list = res[type] ? res[type] : Array.isArray(res) ? res : [];
    list = list.map((_) => {
      if (!_.label) {
        _.label = _.brandname;
      }
      return _;
    });
    yield put(taskActions.fetchDefinedListSuccess({ [type]: list }));
  });
}

function* createNewTask({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const res = yield axios.post(TASK_PATH, payload);
    yield setAlertAndLoading(false);
    yield put(taskActions.createTaskSuccess(res));
    yield setAlertAndLoading(false, { message: "Task created Successfully." });
    // Extract the current pageNo from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const pageNo = urlParams.get("page") || "recent"; // Default to 'recent' if no page query

    // Call fetchAllTasks with the current pageNo
    yield put(taskActions.fetchAllTasks({ pageNo }));
    if (res.activity_type) {
      yield put(
        taskActions.addCustomType({
          id: res.activity_type,
          label: res.activity_type,
        })
      );
    }
    if (res.source === "Custom") {
      yield put(taskActions.fetchCustomTypeList());
    }
  }, "Failed to create task.");
}

function* fetchAllTasks({ payload }) {
  yield requestHandler(function* () {
    const pageNo = payload.pageNo || 1;
    const pageSize = payload.pageSize || TASKS_PER_PAGE;
    const search = payload.search || {};
    const searchText = payload.searchText || "";
    const type = payload.type || "";
    let response = null;
    let tasksList = [];
    let totalCount = 0;
    try {
      if (
        (type && type !== "") ||
        (search && Object.keys(search).length > 0) ||
        (searchText && searchText !== "")
      ) {
        // Case 1: Type + Source search
        if (type && Object.keys(search).length > 0) {
          response = yield axios.post(
            `${TASK_PATH}search_type_source/?page=${pageNo}&per_page=${pageSize}`,
            { type, task: search }
          );
          if (Array.isArray(response) && !response.errors) {
            tasksList = response.slice(0, -1);
            totalCount = response[response.length - 1]?.tasks_count || 0;
          } else {
            tasksList = [];
            totalCount = 0;
          }
        }
        // Case 2: Type-only search
        else if (type) {
          response = yield axios.post(
            `${TASK_PATH}search_type_source/?page=${pageNo}&per_page=${pageSize}`,
            { type, task: {} }
          );
          if (Array.isArray(response) && !response.errors) {
            tasksList = response.slice(0, -1);
            totalCount = response[response.length - 1]?.tasks_count || 0;
          } else {
            tasksList = [];
            totalCount = 0;
          }
        }
        // Case 3: Free-text search
        else if (searchText) {
          response = yield axios.post(
            `${TASK_PATH}search_str?page=${pageNo}&per_page=${pageSize}`,
            { search_str: searchText }
          );
          if (response && response.tasks && !response.errors) {
            tasksList = response?.tasks || [];
            totalCount = response?.tasks_count || 0;
          } else {
            tasksList = [];
            totalCount = 0;
          }
        }
      } else {
        // Case 4: Recent tasks
        if (pageNo === "recent") {
          response = yield axios.get(`${TASK_PATH}latest_tasks`);
          if (Array.isArray(response) && !response.errors) {
            tasksList = response.slice(0, -1);
            totalCount = response[response.length - 1]?.tasks_count || 0;
          } else {
            tasksList = [];
            totalCount = 0;
          }
        }
        // Case 5: Default task list fetch
        else {
          response = yield axios.get(
            `${TASK_PATH}?page=${pageNo}&per_page=${pageSize}`
          );
          if (Array.isArray(response) && !response.error) {
            tasksList = response.slice(0, -1);
            totalCount = response[response.length - 1]?.tasks_count || 0;
          } else {
            tasksList = [];
            totalCount = 0;
          }
        }
        yield put(taskActions.resetTypeFilter());
      }
      // Process tasks
      if (Array.isArray(tasksList)) {
        const processedTasks = [];
        for (const task of tasksList) {
          const processedTask = yield getTask(task, false);
          // Replace aging "N/A" with "-"
          if (processedTask.aging === "N/A") {
            processedTask.aging = "-";
          }
          // Filter unique task_responders based on ent_usr_id
          if (processedTask.task_responders) {
            const uniqueResponders = {};
            processedTask.task_responders =
              processedTask.task_responders.filter((responder) => {
                if (!uniqueResponders[responder.ent_usr_id]) {
                  uniqueResponders[responder.ent_usr_id] = true;
                  return true;
                }
                return false;
              });
          }
          processedTask.status = processedTask.status || "Pending";
          processedTasks.push(processedTask);
        }
        tasksList = processedTasks;
      }

      // Update store
      yield put(taskActions.setTasks(tasksList));
      yield put(taskActions.setTotalTasks(totalCount));
      yield put(taskActions.fetchTasksSuccess(tasksList));
    } catch (error) {
      console.error("Error fetching tasks:", error.message);
      yield put(taskActions.setTasks([]));
      yield put(taskActions.setTotalTasks(0));
      yield put(taskActions.setEmptyMessage("Failed to load Task List."));
      yield put(taskActions.fetchTasksFailure(error.message));
    }
  }, "Failed to load Task List.");
}

function* fetchDepartmentAuditee({ payload }) {
  yield requestHandler(function* () {
    const { department, orgId, currentUserOrg } = payload;
    let responsible;
    if (currentUserOrg === orgId) {
      responsible = yield axios.post(TASK_PATH + "responsible_user_list", {
        department_name: department,
      });
    } else {
      const query = `mapped_org_id=${orgId}${
        department ? `&department_name=${department}` : ""
      }`;
      responsible = yield axios.get(
        "/consoles/get_mapped_user_details?" + query
      );
      responsible = responsible.mapped_user_list;
    }
    if (!Array.isArray(responsible)) {
      responsible = [];
    }
    responsible = responsible.map((_r) => {
      if (Array.isArray(_r)) {
        return { label: _r[0], id: _r[1], guid: _r[2] };
      } else {
        return {
          label: `${_r.firstname} ${_r.lastname}`,
          id: _r.id,
          guid: _r.guid,
        };
      }
    });
    yield put(
      taskActions.deptResponsibleSuccess({ department, responsible, orgId })
    );
  });
}
function truncateName(name, maxLength = 48) {
  if (name.length > maxLength) {
    const lastSpaceIndex = name.lastIndexOf(" ", maxLength);
    return (
      name.substring(0, lastSpaceIndex > 0 ? lastSpaceIndex : maxLength) + "..."
    );
  }
  return name;
}
function* fetchTaskByGuid({ payload }) {
  yield requestHandler(function* () {
    const { guid } = payload;
    // Extract current user details
    const currentUser = yield select(getCurrentUser);
    const userId = currentUser?.id;
    const roleName = currentUser?.role?.name;

    // Check if roleId and roleName are available
    if (!userId || !roleName) {
      console.error("User details are missing");
      return;
    }
    let task = yield axios.get(
      `${TASK_PATH}get_task_by_guid?guid=${guid}&ent_usr_id=${userId}&role_name=${encodeURIComponent(
        roleName
      )}`
    );
    // let task = yield axios.get(`${TASK_PATH}get_task_by_guid?guid=${guid}`);
    if (
      task &&
      (task?.message ===
        "Access Restricted: You are not a responder for this task." ||
        task.data?.message ===
          "Access Restricted: You are not a responder for this task.")
    ) {
      task = "ACCESS_RESTRICTED";
    } else if (task && task.message === "Record Not Found") {
      task = "NOT_FOUND";
    } else {
      task = yield getTask(task);
      //filter out the duplicate task responders
      if (task.task_responders) {
        const uniqueResponders = {};
        task.task_responders = task.task_responders.filter((responder) => {
          if (!uniqueResponders[responder.ent_usr_id]) {
            uniqueResponders[responder.ent_usr_id] = true;
            return true;
          }
          return false;
        });
      }
      if (task.aging === "N/A") {
        task.aging = "-";
      }
    }
    yield put(taskActions.setCurrentTask(task));
  }, "Failed to load task");
}

function* commentAPI(payload) {
  const res = yield axios.post(
    "/task_blueprint/comment",
    payload,
    axios.getFormDataContentType()
  );
  return res;
}

function* updateTask({ payload }) {
  const {
    taskId,
    updatedVal,
    addResponder,
    removeAllResponders,
    popup,
    comment,
    callback,
    silentUpdate,
  } = payload;
  yield requestHandler(function* () {
    let fetch = true;
    // Check for pin task limit
    if (updatedVal.pin_task === true) {
      const response = yield axios.get(`${TASK_PATH}/pin_task_count`);
      if (response.pin_task_count >= 5) {
        yield setAlertAndLoading(false, {
          message: "You have already pinned the maximum allowed tasks.",
        });
        fetch = false;
      }
    }
    if (fetch) {
      // Handle adding and removing responders, comments
      let responderPayload = {
        task_id: taskId,
        responder: { ent_usr_id: null },
      };
      if (removeAllResponders) {
        const task = yield select((state) => state.task.currentTask);
        for (let i = 0; i < task.task_responders.length; i++) {
          responderPayload.responder.ent_usr_id =
            task.task_responders[i].ent_usr_id;
          yield axios.put(
            "/task_blueprint/remove_task_responder",
            responderPayload
          );
        }
      }
      if (addResponder) {
        responderPayload.responder.ent_usr_id = addResponder;
        yield axios.put("/task_blueprint/add_task_responder", responderPayload);
      }
      // NJ24: Closure date handled in backend
      // Check if the task status is being changed to "Closed"
      // if (updatedVal.status === 'Closed') { //
      //   updatedVal.closure_date = getDateInFormat();
      // }
      // Update the task
      let task = yield axios.put(`${TASK_PATH}${taskId}`, { task: updatedVal });
      if (comment?.length > 0) {
        const commentFormData = new FormData();
        commentFormData.append(`comment[comment]`, comment);
        commentFormData.append("task_id", JSON.stringify(taskId));
        yield commentAPI(commentFormData);
      }
      // task = yield axios.get(`${TASK_PATH}get_task_by_guid?guid=${task.guid}`);
      task = yield put(
        taskActions.fetchTaskByGuid({
          guid: task.guid,
        })
      );

      task = yield getTask(task);
      yield put(taskActions.setCurrentTask(task));
      // yield put(taskActions.fetchTaskByGuid({ guid: task.guid }));
      // Set the updated task in the store
      const tasks = yield select((state) => state.task.tasks);
      if (Array.isArray(tasks) && tasks.length > 0) {
        const updatedTasks = tasks.map((t) => (t.id === taskId ? task : t));
        yield put(taskActions.setTasks(updatedTasks)); // Update the tasks list in the store
      }
      if (!silentUpdate) {
        yield setAlertAndLoading(false, {
          message: Boolean(popup && popup.message)
            ? popup.message
            : "Task updated Successfully",
        });
      }
      // Refetch tasks for page=1 if pin or unpin action was taken
      if (typeof updatedVal.pin_task === "boolean") {
        yield put(
          taskActions.fetchAllTasks({
            pageNo: 1,
            pageSize: 9,
            search: {},
            searchText: "",
            type: "",
          })
        );
        if (callback) callback();
      }
    }
  }, "Failed to update task.");
}

function* deleteTask({ payload }) {
  const { taskId } = payload;
  yield requestHandler(function* () {
    const res = yield axios.delete(TASK_PATH + taskId);
    yield put(taskActions.deleteTaskSuccess(res));
  });
}

function* addTaskComment({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.post(
      "/task_blueprint/comment",
      payload,
      axios.getFormDataContentType()
    );
    const guid = res?.guid;
    if (guid) {
      // let task = yield axios.get(`${TASK_PATH}get_task_by_guid?guid=${guid}`);
      let task = yield put(
        taskActions.fetchTaskByGuid({
          guid: guid,
        })
      );
      task = yield getTask(task);
      yield put(taskActions.setCurrentTask(task));
    }
    yield setAlertAndLoading(false, { message: "Comment added Successfully." });
  }, "Failed to add comment");
}
function* addTaskResponder({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const { comment, ...body } = payload;
    // Step 1: Add Task Responder
    let res = yield axios.put("/task_blueprint/add_task_responder", body);
    // Step 2: If a comment is provided, post it using the comment API
    if (comment) {
      const commentFormData = new FormData();
      commentFormData.append(`comment[comment]`, comment);
      commentFormData.append("task_id", JSON.stringify(body.task_id));
      res = yield commentAPI(commentFormData);
    }
    const task = yield getTask(res);
    yield put(taskActions.setCurrentTask(task));
    yield setAlertAndLoading(false, {
      message: "Task Responder added successfully.",
    });
  }, "Failed to add responder");
}
function* removeTaskResponder({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const { comment, ...body } = payload;
    let res = yield axios.put("/task_blueprint/remove_task_responder", body);
    if (comment) {
      const commentFormData = new FormData();
      commentFormData.append(`comment[comment]`, comment);
      commentFormData.append("task_id", JSON.stringify(body.task_id));
      yield commentAPI(commentFormData);
      // res = yield axios.get(`${TASK_PATH}get_task_by_guid?guid=${res.guid}`);
      res = yield put(
        taskActions.fetchTaskByGuid({
          guid: res.guid,
        })
      );
    }
    const task = yield getTask(res);
    yield put(taskActions.setCurrentTask(task));
    yield setAlertAndLoading(false, {
      message: "Task Responder removed successfully.",
    });
  }, "Failed to remove responder");
}
function* updateDeptTaskResponder({ payload }) {
  const { body, isChange } = payload;
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    yield axios.post("/task_blueprint/set_task_dept_responder", body);
    const message = isChange
      ? "Department Responder changed successfully."
      : "Department Responder assigned successfully.";
    yield setAlertAndLoading(false, { message });
    if (isChange) {
      // yield put(taskActions.fetchAllTasks());
      yield put(
        taskActions.fetchAllTasks({
          pageNo: 1,
          pageSize: 9,
          search: {},
          searchText: "",
          type: "",
        })
      );
    }
  }, "Failed to change responder");
}

function* attachTaskEvidence({ payload }) {
  const { formData } = payload;
  yield requestHandler(function* () {
    const res = yield axios.post(TASK_PATH + "evidence", formData, {
      "content-type": "multipart/form-data",
    });
    yield put(taskActions.attachTaskEvidenceSuccess(res));
    yield put(taskActions.updateTaskSuccess(res));
    yield setAlertAndLoading(false, {
      message: "Evidence attached Successfully.",
    });
  });
}

function* filterTasks({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.post(
      TASK_PATH +
        `search/?page=${payload.pageNo || 1}&per_page=${TASKS_PER_PAGE}`,
      { task: payload.filter }
    );
    yield put(taskActions.fetchTasksSuccess(res));
  });
}

function* searchTasks({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.post(
      TASK_PATH +
        `search_str?page=${payload.pageNo || 1}&per_page=${TASKS_PER_PAGE}`,
      { search_str: payload.searchText }
    );
    yield put(taskActions.fetchTasksSuccess(res));
  });
}

function* fetchDashBoardContent() {
  yield requestHandler(function* () {
    const res = yield axios.get(TASK_PATH + `dashboard`);
    let deptVsPriority = [],
      deptStatus = [],
      statusVsPriority = [],
      asmtVsPriority = [],
      actvVsPriority = [],
      incVsPriority = [],
      contVsPriority = [];
    if (res.dept_priority_list) {
      deptVsPriority = getDashboardFormat(res.dept_priority_list);
    }
    if (res.dept_status_list) {
      deptStatus = getDashboardFormat(res.dept_status_list);
    }
    if (res.status_priority_list) {
      statusVsPriority = getDashboardFormat(res.status_priority_list);
    }
    if (res.asmt_priority_list) {
      asmtVsPriority = getSourceTasksMap(res.asmt_priority_list);
    }
    if (res.activity_type_priority_list) {
      actvVsPriority = getSourceTasksMap(res.activity_type_priority_list);
    }
    if (res.incident_task_list) {
      incVsPriority = getSourceTasksMap(res.incident_task_list);
    }
    if (res.continuer_task_list) {
      contVsPriority = getSourceTasksMap(res.continuer_task_list);
    }
    yield put(
      taskActions.fetchDashboardSuccess({
        deptVsPriority,
        deptStatus,
        statusVsPriority,
        asmtVsPriority,
        actvVsPriority,
        incVsPriority,
        contVsPriority,
      })
    );
  });
}

function* bulkUploadTasks({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.post(
      TASK_PATH + "bulk_task_upload",
      payload,
      axios.getFormDataContentType()
    );
    yield put(taskActions.setBulkUploadStatus(res));
    yield put(taskActions.fetchBulkUploads());
    yield setAlertAndLoading(false, { message: "Tasks Uploaded Successfully" });
  });
}

function* fetchBulkUploads() {
  yield requestHandler(function* () {
    let res = yield axios.get(TASK_PATH + "file_uploads");
    if (Array.isArray(res)) {
      res = res.map((_) => {
        let upload = { ..._ };
        upload.created_at = getDateInFormat(_.created_at);
        return upload;
      });
    } else {
      res = [];
    }
    yield put(taskActions.setBulkUploadHistory(res));
  }, "Failed to fetch buld upload history");
}
function* downloadBulkUploadHistory({ payload }) {
  yield requestHandler(function* () {
    const { error_file_path, document_file_name } = payload;
    let cont = yield axios.post("/tasks/task_upload_status_file", {
      file_location: error_file_path,
    });
    cont = cont.replaceAll("|", ",");
    var blob = new Blob([cont], { type: "text/csv;charset=utf-8;" });
    let fileName = String(document_file_name).replace(".", "_Status.");
    saveAs(blob, fileName);
  }, "Failed to fetch buld upload history");
}

function* fetchNotificationFreqList() {
  yield requestHandler(function* () {
    const res = yield axios.get(TASK_PATH + "notification_frequency_list");
    yield put(taskActions.notifFreqListSuccess(res.noti_frequency_list || []));
  });
}

function* updateNotifFreq({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.post(TASK_PATH + "update_noti_freq", {
      task: { notification_frequency: payload },
    });
    yield put(
      taskActions.updateNotifFreqSuccess(res.noti_frequency_list || [])
    );
    yield setAlertAndLoading(false, {
      message: "Settings Updated Successfully",
    });
  });
}
function* fetchRepeatitionList() {
  yield requestHandler(function* () {
    const res = yield axios.get(TASK_PATH + "repetition_occurs_list");
    let repeatitionOccursList = res.repetition_occurs_list.map((rep) => ({
      label: rep,
      id: rep,
    }));
    yield put(taskActions.setRepeatitionList(repeatitionOccursList || []));
  });
}
function* fetchAuditAssessmentsList() {
  yield requestHandler(function* () {
    let res = yield axios.get(TASK_PATH + "assessments");
    res = Array.isArray(res) ? res : [];
    let assessments_list = res.map((assmnt) => {
      // let id = assmnt.lib1_model_id
      let id = assmnt.id;
      let label = assmnt.title;
      return { label, id };
    });
    yield put(taskActions.setAuditAssessmentsList(assessments_list || []));
  });
}
function* fetchActivityTypeList() {
  yield requestHandler(function* () {
    let res = yield axios.get(TASK_PATH + "activity_type_list");
    res = Array.isArray(res.activity_type_list) ? res.activity_type_list : [];
    let activityTypeList = res.map((activity) => ({
      label: activity,
      id: activity,
    }));
    yield put(taskActions.setActivityTypeList(activityTypeList || []));
  });
}
function* fetchCustomTypeList() {
  yield requestHandler(function* () {
    let res = yield axios.get(TASK_PATH + "custom_type_list");
    res = Array.isArray(res.activity_type_list) ? res.activity_type_list : [];
    let customTypeList = res.map((activity) => ({
      label: activity,
      id: activity,
    }));
    yield put(taskActions.setCustomTypeList(customTypeList || []));
  });
}
function* fetchIncidentTypeList() {
  yield requestHandler(function* () {
    const res = yield axios.get(TASK_PATH + "incidents");
    let incidentTypeList = (res.incidents || []).map((inc) => ({
      label: inc.title,
      id: inc.id,
      guid: inc.guid,
    }));
    yield put(taskActions.setIncidentTypeList(incidentTypeList || []));
  });
}
function* fetchContinuityTypeList() {
  yield requestHandler(function* () {
    const res = yield axios.get(TASK_PATH + "continuers");
    let continuityTypeList = (res.continuers || []).map((con) => ({
      label: con.title,
      id: con.id,
      guid: con.guid,
    }));
    yield put(taskActions.setContinuityTypeList(continuityTypeList || []));
  });
}
function* fetchTaskBlueprintTemplates() {
  yield requestHandler(function* () {
    let res = yield axios.get("task_blueprint/get_task_blueprint_templates");
    let list = Array.isArray(res.task_blueprint_list)
      ? res.task_blueprint_list
      : [];
    list = list.map((_) => {
      return { ..._, label: _.title };
    });
    yield put(taskActions.setTaskBlueprintTemplates(list));
  }, "Failed to fetch Task Blueprint Templates.");
}
function* fetchTaskCreatedBlueprintTemplates() {
  yield requestHandler(function* () {
    let res = yield axios.get("tasks/get_task_created_blueprint_templates");
    let list = Array.isArray(res.task_blueprint_list)
      ? res.task_blueprint_list
      : [];
    list = list.map((_) => {
      return { ..._, label: _.title };
    });
    yield put(taskActions.setTaskCreatedBlueprintTemplates(list));
  }, "Failed to fetch Task Blueprint Templates.");
}
function* subscribeBlueprint({ payload }) {
  yield requestHandler(function* () {
    yield axios.post("/task_blueprint/subscribe_task_blueprint", payload);
    yield setAlertAndLoading(false, { message: "Tasks Created Successfully" });
    // yield put(
    //   taskActions.fetchAllTasks({
    //     pageNo: 1,
    //     pageSize: 9,
    //     search: {},
    //     searchText: "",
    //     type: "",
    //   })
    // );
    // Extract the current page from the URL query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const page = queryParams.get("page") || "1";
    yield put(
      taskActions.fetchAllTasks({
        pageNo: page,
        pageSize: 9,
        search: {},
        searchText: "",
        type: "",
      })
    );
  });
}
function* fetchDateRangeEnums() {
  yield requestHandler(function* () {
    let list = [
      { id: "last_7_days", label: "Last 7 Days" },
      { id: "last_week", label: "Last Week (Sun-Sat)" },
      { id: "last_30_days", label: "Last 30 Days" },
      { id: "last_month", label: "Previous Month" },
      { id: "last_90_days", label: "Last 90 Days" },
      { id: "previous_quarter", label: "Previous Quarter" },
      { id: "last_365_days", label: "Last 365 Days" },
      { id: "previous_year", label: "Previous Year (Apr-Mar)" },
      { id: "over_all", label: "Entire History" },
    ];
    yield put(taskActions.setDateRangeEnums(list));
  }, "Failed to load Date Range list");
}
function* fetchArcStats({ payload }) {
  yield requestHandler(function* () {
    const body = {
      date_range_type: payload.date_range_type,
      start_date: payload.start_date,
      end_date: payload.end_date,
    };
    const res = yield axios.post(`/tasks/arc_central_dashboard`, body);
    if (payload.stats_type === "dept_stats") {
      yield put(taskActions.setDeptStatusStats(res?.dept_status_list));
      yield put(
        taskActions.setDeptStatusTotalAvgAging(
          res?.aggregated_dept_status_results?.avg_aging
        )
      );
    } else if (payload.stats_type === "dept_priority_stats") {
      yield put(taskActions.setDeptPriorityStats(res?.dept_priority_list));
      yield put(
        taskActions.setDeptPriorityTotalAvgAging(
          res?.aggregated_dept_prio_results?.avg_aging
        )
      );
    } else if (payload.stats_type === "priority_stats") {
      yield put(taskActions.setPriorityStatusStats(res?.priority_status_list));
      yield put(
        taskActions.setPriorityStatusTotalAvgAging(
          res?.aggregated_priority_stat_results?.avg_aging
        )
      );
    } else {
      yield put(taskActions.setStatistics(res));
    }
  }, "Failed to load Statistics");
}
function* exportTasks({ payload }) {
  yield requestHandler(function* () {
    try {
      const res = yield axios.post(TASK_PATH + "task_report", payload, {
        responseType: "blob",
      });

      const blob = new Blob([res.data], { type: "text/csv;charset=utf-8;" });
      const filename = `TCHZ0005-CSVZ${getDateInFormat(
        undefined,
        "YYDDMM"
      )}_Arc_Task_Export.csv`;
      saveAs(blob, filename);
    } catch (error) {
      yield setAlertAndLoading(false, {
        success: false,
        message: error?.data?.message,
      });
    }
  }, "Failed to Export Tasks");
}

SagaRegistry.register(function* taskSaga() {
  yield takeEvery("task/getApiKey", getApiKey);
  yield takeEvery("task/fetchDefinedList", fetchDefinedList);
  yield takeEvery("task/fetchMappedOrgs", fetchMappedOrgs);
  yield takeEvery("task/createNewTask", createNewTask);
  yield takeEvery("task/fetchAllTasks", fetchAllTasks);
  yield takeEvery("task/fetchTaskByGuid", fetchTaskByGuid);
  yield takeEvery("task/updateTask", updateTask);
  yield takeEvery("task/deleteTask", deleteTask);
  yield takeEvery("task/addTaskComment", addTaskComment);
  yield takeEvery("task/addTaskResponder", addTaskResponder);
  yield takeEvery("task/removeTaskResponder", removeTaskResponder);
  yield takeEvery("task/updateDeptTaskResponder", updateDeptTaskResponder);
  yield takeEvery("task/attachTaskEvidence", attachTaskEvidence);
  yield takeEvery("task/filterTasks", filterTasks);
  yield takeEvery("task/searchTasks", searchTasks);
  yield takeEvery("task/fetchDashBoardContent", fetchDashBoardContent);
  yield takeEvery("task/bulkUploadTasks", bulkUploadTasks);
  yield takeEvery("task/fetchBulkUploads", fetchBulkUploads);
  yield takeEvery("task/downloadBulkUploadHistory", downloadBulkUploadHistory);
  yield takeEvery("task/fetchDepartmentAuditee", fetchDepartmentAuditee);
  yield takeEvery("task/fetchNotificationFreqList", fetchNotificationFreqList);
  yield takeEvery("task/updateNotifFreq", updateNotifFreq);
  yield takeEvery("task/setConfirmationYield", setConfirmationYield);
  yield takeEvery("task/fetchDateRangeEnums", fetchDateRangeEnums);
  yield takeEvery("task/fetchRepeatitionList", fetchRepeatitionList);
  yield takeEvery("task/fetchAuditAssessmentsList", fetchAuditAssessmentsList);
  yield takeEvery("task/fetchActivityTypeList", fetchActivityTypeList);
  yield takeEvery("task/fetchCustomTypeList", fetchCustomTypeList);
  yield takeEvery("task/fetchIncidentTypeList", fetchIncidentTypeList);
  yield takeEvery("task/fetchContinuityTypeList", fetchContinuityTypeList);
  yield takeEvery(
    "task/fetchTaskBlueprintTemplates",
    fetchTaskBlueprintTemplates
  );
  yield takeEvery(
    "task/fetchTaskCreatedBlueprintTemplates",
    fetchTaskCreatedBlueprintTemplates
  );
  yield takeEvery("task/subscribeBlueprint", subscribeBlueprint);
  yield takeEvery("task/fetchArcStats", fetchArcStats);
  yield takeEvery("task/exportTasks", exportTasks);
});
