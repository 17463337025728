import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form } from "../../components";
import Fields from "./FormFields.json";
import dayjs from "dayjs";
import {
  getDepartments,
  getDateInFormat,
  getTaskProrityTypes,
  taskActions,
  getTaskSourcesFromType,
  getActiveUsers,
  getRepeatitionOccursList,
  getTaskSourceTypes,
  getCurrentUser,
  getTaskDeptResponders,
  commonActions,
} from "../../store";
import { InfoIcon } from "./Helpers";

const RepeationYearMatch = {
  Daily: 1,
  Weekly: 1,
  Fortnightly: 1,
  Monthly: 1,
  Quarterly: 3,
  "Half-Yearly": 3,
  Annually: 3,
  None: 0,
  Once: 0,
};
const priorityDueDateMap = {
  Emergency: 7,
  Urgent: 15,
  Critical: 15,
  High: 30,
  Medium: 60,
  Low: 90,
  Minimal: 180,
  Default: 60,
  Least: 360,
};

export const CreateTaskForm = (props) => {
  const { open, onClose } = props;
  const _ref = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const departments = useSelector(getDepartments);
  const priorities = useSelector(getTaskProrityTypes);
  const activeOrgUsers = useSelector(getActiveUsers);
  const repeatitionOccursList = useSelector(getRepeatitionOccursList);
  const deptResponders = useSelector(getTaskDeptResponders);
  const [state, setState] = useState({
    titleWarning: false,
    task: {
      name: "",
      priority: "",
      department: "",
      desc: "",
      source: "",
      start_date: "",
      end_date: "",
      repetition_occurs: { label: "Once", id: "Once" },
      evidence_expected: "",
      external_links: "",
      activity_type: "",
      due_date: "",
    },
    responder: {},
    isValid: false, //state to manage form validity
  });
  const sources = useSelector(
    getTaskSourcesFromType.bind(
      null,
      true,
      state.task.source && state.task.source.id
    )
  );

  const Actions = [
    { type: "cancel", label: "Cancel", ClassName: " f14 exo2 bold-600" },
    {
      type: "primary",
      label: "Create",
      ClassName: " f14 exo2 bold-600",
      disabled: !state.isValid,
      style: { backgroundColor: state.isValid ? "#1A32C4" : "#d9d9d9" },
    },
  ];
  const getActions = () => {
    return Actions.map((_, i) => {
      let action = { ..._ };
      action.onClick = i === 0 ? handleClose : handleSubmitNewTask;
      return action;
    });
  };
  const handleClose = () => {
    resetFields(); // Reset fields to initial values
    onClose();
  };
  // Reset field types when closing the modal
  const resetFields = () => {
    Fields.Task[7]["type"] = "select";
    Fields.Task[7]["allowFreeType"] = false;
  };
  // Validation Function to enable/disable Create button
  const validateForm = (task, responder) => {
    // const { task, responder } = state;
    return Boolean(
      task.name.trim() &&
        task.priority &&
        task.due_date &&
        task.department &&
        task.source &&
        task.activity_type &&
        (task.responder?.id || responder.ent_usr_id)
    );
  };

  const handleSubmitNewTask = () => {
    const payload = transformStateToPayload(state, user);
    dispatch(taskActions.createNewTask(payload));
    onClose();
  };

  const getFields = () => {
    return Fields.Task.map((_, index) => {
      let field = { ..._, key: index };
      if (field.attribute === "due_date") {
        field.placeholder = getDateInFormat();
        field.minDate = getDateInFormat();
      }
      if (field.attribute === "plan_date") {
        field.prefixIcon = (
          <InfoIcon placement="bottom" overlayClassName="custom-tooltip" />
        );
        field.value = state.task.due_date
          ? getDateInFormat(state.task.due_date)
          : getDateInFormat();
      }
      if (
        field.attribute === "repetition" ||
        field.attribute === "start_date" ||
        field.attribute === "end_date"
      ) {
        field.children.map((child) => {
          if (
            child.attribute === "start_date" ||
            child.attribute === "end_date"
          ) {
            child.placeholder = getDateInFormat();
            child.minDate = getDateInFormat();
          }
        });
      }
      if (state.titleWarning && _.attribute === "name") {
        field.showWarningIcon = true;
        field.showWarningText = "Title should not exceed 64 characters.";
      }
      return field;
    });
  };

  const getOptions = (attribute) => {
    let options = [];
    let typeOptions = getTaskSourceTypes();
    switch (attribute) {
      case "department":
        options = departments.map((dept, index) => ({ ...dept, key: index }));
        break;
      case "priority":
        options = priorities.map((priority, index) => ({
          ...priority,
          key: index,
        }));
        break;
      case "responder":
        options = (activeOrgUsers || []).map((user, index) => {
          const [name, title] = user._designation
            .split("-")
            .map((part) => part.trim()); // Split the name and title by hyphen and trim spaces
          let formattedTitle = title.replace(" - ", ", ");
          return {
            label: `${name}, ${formattedTitle}`,
            id: user.id,
            key: index,
          };
        });
        break;
      case "repetition":
        options = repeatitionOccursList.map((item, index) => ({
          ...item,
          key: index,
        }));
        break;
      case "source":
        options = typeOptions.map((item, index) => ({ ...item, key: index }));
        break;
      case "activity_type":
        options = sources.map((item, index) => ({ ...item, key: index }));
        break;
      default:
        options = options.map((item, index) => ({ ...item, key: index }));
        break;
    }
    return options;
  };
  const handleValueChange = (e) => {
    const { name, value } = e.target;
    const task = { ...state.task };
    let titleWarning = state.titleWarning;
    const responder = { ...state.responder };

    task[name] = value;

    switch (name) {
      case "priority":
        const dueDateOffset = priorityDueDateMap[value.label];
        if (dueDateOffset) {
          task.due_date = dayjs()
            .add(dueDateOffset, "day")
            .format("YYYY-MM-DD");
        }
        break;
      case "department":
        const users = getOptions("responder");
        let deptResp = (deptResponders || []).find(
          ({ department_name }) => department_name === value.id
        );
        if (deptResp) {
          task.responder = (users || []).find(
            ({ id }) => deptResp.ent_usr_id === id
          );
        }
        break;
      case "responder":
        responder["ent_usr_id"] = value.id;
        break;
      case "name":
        titleWarning = value.length > 64;
        break;
      case "source":
        // Reset the activity_type field when source changes
        task.activity_type = "";

        // Change the field type to autocomplete if the source is custom
        if (value.id === "custom") {
          Fields.Task[7]["type"] = "autocomplete";
          Fields.Task[7]["allowFreeType"] = true;
        } else {
          resetFields(); // Reset the field type when the source changes to non-custom
        }
        break;
      case "repetition_occurs":
        if (value.id === "None") {
          task.end_date = null;
          task.start_date = null;
        } else {
          let yearVal = RepeationYearMatch[value.id];
          if (!task.start_date) {
            task.start_date = dayjs();
          }
          if (yearVal) {
            task.end_date = dayjs(task.start_date).add(yearVal, "year");
          }
        }
        break;
      default:
        break;
    }

    // Re-validate the form whenever a value changes
    const isValid = validateForm(task, responder);

    setState((prevState) => ({
      ...prevState,
      task,
      responder,
      titleWarning,
      isValid, // Update the `isValid` state
    }));
  };

  useEffect(() => {
    if (open) {
      dispatch(commonActions.fetchTaskDeptResponders());
      const handleKeyDown = (event) => {
        if (event.key === "Enter" && state.isValid) {
          handleSubmitNewTask();
        }
      };
      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [open, state.isValid]);
  return (
    <>
      <Modal
        width={850}
        open={open}
        className="new-task-modal"
        actions={getActions()}
        onCancel={onClose}
        title={<span className="exo2 f19 c238787">New Task</span>}
      >
        {open && (
          <Form
            showColon
            ref={_ref}
            onSubmit={handleSubmitNewTask}
            className="form row fwarp h-btn new-task-form"
            formData={{ ...state.task }}
            Fields={getFields()}
            getOptions={getOptions}
            onChange={handleValueChange}
          />
        )}
      </Modal>
    </>
  );
};
const transformStateToPayload = (state, user) => {
  const { task } = state;
  // Validate if start_date and end_date are valid dayjs objects
  const getDateValue = (iDate) => {
    if (iDate && dayjs(iDate).isValid()) {
      return dayjs(iDate).startOf("day").toISOString();
    }
    return null;
  };
  const payload = { task: {}, responder: {} };
  payload.task = {
    name: task.name,
    desc: task.desc,
    department: task.department?.id || "",
    priority: task.priority?.id || "",
    assignee_id: task.responder?.id || "",
    due_date: getDateValue(task.due_date),
    // start_date: getDateValue(task.start_date),
    // end_date: getDateValue(task.end_date),
    // repetition_occurs: task.repetition_occurs?.id || '',
    status: "Pending",
    evidence_expected: task.evidence_expected,
    external_links: task.external_links,
    pin_task: false,
    source: task.source?.label,
    activity_type: task.activity_type,
  };
  if (task.activity_type) {
    if (task.source && task.source.fetchKey) {
      payload.task[task.source.fetchKey] = task.activity_type.id;
    } else {
      payload.task.asmt_id = task.activity_type.id;
    }
  }
  payload.responder = { ent_usr_id: task.responder?.id || "" };
  return payload;
};
