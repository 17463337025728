import "./saga";
export * from "./slice";

export const getTaskSourceTypes = () => {
  return [
    { label: "Audit", id: "audit", fetchKey: "asmt_id", queryKey: "audit" },
    {
      label: "Activity",
      id: "activity",
      fetchKey: "activity_type",
      queryKey: "activity",
    },
    {
      label: "Custom",
      id: "custom",
      fetchKey: "activity_type",
      queryKey: "custom",
    },
    {
      label: "Incident",
      id: "incident",
      fetchKey: "incident_id",
      queryKey: "incident",
    },
    {
      label: "Continuity",
      id: "continuity",
      fetchKey: "continuer_id",
      queryKey: "continuity",
    },
  ];
};
export const getComplianceTask = () => {
  return [
    {
      label: "Compliance",
      id: "blueprint",
      fetchKey: "task_blueprint_id",
      queryKey: "blueprint",
    },
  ];
};

const getTaskState = (state) => state.task;

export const getTasks = (state) => {
  const _state = getTaskState(state);
  return _state.tasks;
};
export const getCurrentTask = (state) => getTaskState(state).currentTask;
export const getTotalCount = (state) => getTaskState(state).totalCount;
export const getBulkUploadsHistory = (state) =>
  getTaskState(state).bulkUploadsHistory;
export const getDashboard = (state) => getTaskState(state).dashboard;
export const getResponsibles = (state) => getTaskState(state).responsibles;
export const getNotificationFreq = (state) =>
  getTaskState(state).notificationFreq;
export const isObservAdded = (state) => getTaskState(state).observAdded;
export const getOpenTask = (state) => getTaskState(state).openTask;
export const getDateRangeEnums = (state) => getTaskState(state).dateRangeEnumns;
export const getRepeatitionOccursList = (state) =>
  getTaskState(state).repeatitionsList;
export const getAuditAssessmentsList = (state) =>
  getTaskState(state).auditAssessments;
export const getActivityTypeList = (state) =>
  getTaskState(state).activityTypeList;
export const getCustomTypeList = (state) => getTaskState(state).customTypeList;
export const getIncidentTypeList = (state) =>
  getTaskState(state).incidentTypeList;
export const getContinuityTypeList = (state) =>
  getTaskState(state).continuityTypeList;
export const getTasksTotalCount = (state) => getTaskState(state).totalTasks;
export const getBulkUploadStatus = (state) =>
  getTaskState(state).bulkUploadingStatus;
export const getTaskBlueprintTemplates = (activeOnly, state) => {
  let taskBlueprintTemplates = getTaskState(state).taskBlueprintTemplates;
  if (activeOnly && Array.isArray(taskBlueprintTemplates)) {
    taskBlueprintTemplates = taskBlueprintTemplates.filter(
      ({ status }) => status === "active"
    );
  }
  return taskBlueprintTemplates;
};
export const getTaskCreatedBlueprintTemplates = (state) =>
  getTaskState(state).taskCreatedBlueprintTemplates;
export const getTaskSourcesFromType = (activeOnly, type, state) => {
  let list = [];
  if (type) {
    switch (type) {
      case "audit":
        list = getAuditAssessmentsList(state);
        break;
      case "activity":
        list = getActivityTypeList(state);
        break;
      case "custom":
        list = getCustomTypeList(state);
        break;
      case "incident":
        list = getIncidentTypeList(state);
        break;
      case "continuity":
        list = getContinuityTypeList(state);
        break;
      case "blueprint":
        list = getTaskBlueprintTemplates(activeOnly, state);
        break;
      default:
        list = [];
        break;
    }
  }
  return Array.isArray(list) ? list : [];
};
export const getArcStats = (state) => getTaskState(state).statistics;
export const getDeptStats = (state) => getTaskState(state).deptStatusStats;
export const getDeptPriorityStats = (state) =>
  getTaskState(state).deptPriorityStats;
export const getPriorityStats = (state) =>
  getTaskState(state).priorityStatusStats;
export const getDeptStatusTotalAvgAging = (state) =>
  getTaskState(state).deptStatusTotalAvgAging;
export const getDeptPriorityTotalAvgAging = (state) =>
  getTaskState(state).deptPriorityTotalAvgAging;
export const getPriorityStatusTotalAvgAging = (state) =>
  getTaskState(state).priorityStatusTotalAvgAging;
export const isTaskOpenState = (iStatus) => {
  return iStatus && iStatus.state === "open";
};
export const isTaskClosedState = (iStatus) => {
  return iStatus && iStatus.state === "closed";
};
export const isTaskInvalidState = (iStatus) => {
  return iStatus && iStatus.state === "invalid";
};
export const isTasksFetched = (state) => getTaskState(state).taskFetched;
