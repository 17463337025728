import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

const initialState = {
  currentTask: null,
  totalCount: 1,
  commentAdding: false,
  evidenceAttaching: false,
  taskFetched: false,
  sortModel: null,
  createSuccess: false,
  updated: false,
  bulkUploadingStatus: false,
  tasks: null,
  bulkUploads: [],
  dashboard: null,
  responsibles: {},
  notificationFreq: [],
  observAdded: false,
  openTask: null,
  searchText: "",
  confirmation: null,
  dateRangeEnumns: [],
  repeatitionsList: [],
  auditAssessments: null,
  activityTypeList: null,
  customTypeList: null,
  incidentTypeList: null,
  continuityTypeList: null,
  taskBlueprintTemplates: null,
  taskCreatedBlueprintTemplates: null,
  totalTasks: 0,
  statistics: {},
  deptStatusStats: {},
  deptPriorityStats: {},
  priorityStatusStats: {},
  deptStatusTotalAvgAging: 0,
  deptPriorityTotalAvgAging: 0,
  priorityStatusTotalAvgAging: 0,
  typeFilter: { type: "", search: "" }, // New typeFilter state
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    fetchDateRangeEnums: () => {},
    setDateRangeEnums: (state, action) => {
      state.dateRangeEnumns = action.payload;
    },
    setConfirmationYield: () => {},
    getApiKey: () => {},
    fetchDefinedList: () => {},
    fetchMappedOrgs: () => {},
    createNewTask: (state) => {
      state.createSuccess = false;
    },
    fetchAllTasks: (state, action) => {
      state.tasks = null;
      state.taskFetched = false; // Reset taskFetched to show the loader
      if (action.payload && action.payload.removeCount) {
        state.totalCount = 0;
      }
    },
    resetTaskCount: (state) => {
      state.totalCount = 0;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    setTotalTasks: (state, action) => {
      state.totalTasks = action.payload;
    },
    fetchTaskByGuid: (state) => {
      state.currentTask = null;
    },
    setCurrentTask: (state, action) => {
      state.currentTask = action.payload;
    },
    updateTask: () => {},
    deleteTask: () => {},
    addTaskComment: (state) => {
      state.commentAdding = true;
    },
    addTaskResponder: () => {},
    removeTaskResponder: () => {},
    updateDeptTaskResponder: () => {},
    attachTaskEvidence: (state) => {
      state.evidenceAttaching = true;
    },
    filterTasks: () => {},
    searchTasks: () => {},
    exportTasks: () => {},
    fetchDashboard: () => {},
    bulkUploadTasks: (state) => {
      state.bulkUploadingStatus = "started";
    },
    setBulkUploadStatus: (state, action) => {
      state.bulkUploadingStatus = action.payload;
    },
    downloadBulkUploadHistory: () => {},
    fetchBulkUploads: () => {},
    subscribeBlueprint: () => {},
    fetchDepartmentAuditee: () => {},
    fetchNotificationFreqList: () => {},
    updateNotifFreq: () => {},
    fetchRepeatitionList: () => {},
    fetchAuditAssessmentsList: () => {},
    fetchActivityTypeList: () => {},
    fetchCustomTypeList: () => {},
    fetchIncidentTypeList: () => {},
    fetchContinuityTypeList: () => {},
    fetchMaxPinCount: () => {},
    fetchTaskBlueprintTemplates: () => {},
    fetchTaskCreatedBlueprintTemplates: () => {},
    fetchArcStats: () => {},
    setRepeatitionList: (state, action) => {
      state.repeatitionsList = action.payload;
    },
    setAuditAssessmentsList: (state, action) => {
      state.auditAssessments = action.payload;
    },
    setActivityTypeList: (state, action) => {
      state.activityTypeList = action.payload;
    },
    setCustomTypeList: (state, action) => {
      state.customTypeList = action.payload;
    },
    setIncidentTypeList: (state, action) => {
      state.incidentTypeList = action.payload;
    },
    setContinuityTypeList: (state, action) => {
      state.continuityTypeList = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setSortModel: (state, action) => {
      state.sortModel = action.payload;
    },
    resetTaskCreateStatus: (state) => {
      state.createSuccess = false;
      state.updated = false;
    },
    fetchDefinedListSuccess: (state, action) => {
      return { ...state, ...action.payload };
    },
    createTaskSuccess: (state, action) => {
      state.tasks = [action.payload, ...state.tasks];
      state.createSuccess = true;
    },
    fetchTasksSuccess: (state, action) => {
      let tasks, totalCount;
      if (Array.isArray(action.payload)) {
        tasks = action.payload.filter((task) => {
          if (task.tasks_count !== undefined) {
            totalCount = task.tasks_count;
            return false;
          }
          return true;
        });
      } else {
        if (action.payload.errors) {
          tasks = [];
          totalCount = 0;
        }
      }
      state.tasks = tasks;
      state.totalCount = totalCount;
    },
    addTaskCommentSuccess: (state) => {
      state.commentAdding = false;
    },
    attachTaskEvidenceSuccess: (state) => {
      state.evidenceAttaching = false;
    },
    updateTaskSuccess: (state, action) => {
      let tasks = state.tasks.map((task) =>
        task.id === action.payload.id ? { ...task, ...action.payload } : task
      );
      state.tasks = tasks;
      state.updated = true;
    },
    // fetchTaskSuccess: (state) => {
    //   state.taskFetched = true;
    // },
    fetchTasksSuccess: (state, action) => {
      let tasks = [];
      let totalCount = 0;

      if (Array.isArray(action.payload)) {
        tasks = action.payload.filter((task) => {
          if (task.tasks_count !== undefined) {
            totalCount = task.tasks_count;
            return false;
          }
          return true;
        });
      } else if (action.payload && action.payload.errors) {
        tasks = [];
        totalCount = 0;
      }

      state.tasks = tasks || [];
      state.totalCount = totalCount || 0;
      state.taskFetched = true;
    },

    fetchTasksFailure(state) {
      state.taskFetched = false;
    },
    fetchDashboardSuccess: (state, action) => {
      state.dashboard = action.payload;
    },
    setBulkUploadHistory: (state, action) => {
      state.bulkUploadsHistory = action.payload;
    },
    deptResponsibleSuccess: (state, action) => {
      let responsibles = { ...state.responsibles };
      responsibles[action.payload.orgId] = {
        ...responsibles[action.payload.orgId],
      };
      responsibles[action.payload.orgId][action.payload.department] =
        action.payload.responsible;
      state.responsibles = responsibles;
    },
    notifFreqListSuccess: (state, action) => {
      state.notificationFreq = action.payload;
    },
    addCustomType: (state, action) => {
      let customTypes = state.customTypes || [];
      if (!customTypes.some((type) => type.id === action.payload.id)) {
        customTypes.push({
          id: action.payload.id,
          label: action.payload.label,
        });
      }
      state.customTypes = customTypes;
    },
    isObserveAdded: (state, action) => {
      state.observAdded = action.payload;
    },
    openTaskSuccess: (state, action) => {
      state.openTask = action.payload.task;
    },
    setConfirmation: (state, action) => {
      state.confirmation = action.payload;
    },
    setTaskBlueprintTemplates: (state, action) => {
      state.taskBlueprintTemplates = action.payload;
    },
    setTaskCreatedBlueprintTemplates: (state, action) => {
      state.taskCreatedBlueprintTemplates = action.payload;
    },
    setDeptPriorityStats: (state, action) => {
      state.deptPriorityStats = { ...action.payload };
    },
    setDeptStatusStats: (state, action) => {
      state.deptStatusStats = { ...action.payload };
    },
    setPriorityStatusStats: (state, action) => {
      state.priorityStatusStats = { ...action.payload };
    },
    setDeptStatusTotalAvgAging: (state, action) => {
      state.deptStatusTotalAvgAging = action.payload;
    },
    setDeptPriorityTotalAvgAging: (state, action) => {
      state.deptPriorityTotalAvgAging = action.payload;
    },
    setPriorityStatusTotalAvgAging: (state, action) => {
      state.priorityStatusTotalAvgAging = action.payload;
    },
    setStatistics: (state, action) => {
      state.statistics = { ...action.payload };
    },
    clearState: () => initialState,
    setTypeFilter: (state, action) => {
      state.typeFilter = action.payload;
    },
    resetTypeFilter: (state) => {
      state.typeFilter = { type: "", search: "" };
    },
  },
});

export const taskActions = taskSlice.actions;
ReducerRegistry.register("task", taskSlice.reducer);
