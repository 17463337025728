import { useEffect, useState } from "react";
import { TableComponent } from "../../StatsTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeptPriorityStats,
  getCurrentUser,
  getDepartments,
  taskActions,
  commonActions,
  getDeptPriorityTotalAvgAging,
} from "../../../store";

const Field = { attribute: "dateRange", type: "select" };

export const DeptVsPriorityStats = (props) => {
  const arcStats = props;
  const [responseData, setResponseData] = useState({});
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const departments = useSelector(getDepartments);
  const deptPriorityStats = useSelector(getDeptPriorityStats);
  const deptPriorityTotalAvgAging = useSelector(getDeptPriorityTotalAvgAging);

  const deptInitialRows = departments.map((deptObj) => {
    return {
      department: deptObj.id,
      total: 0,
      complete: 0,
      inprogress: 0,
      pending: 0,
      other: 0,
    };
  });
  deptInitialRows.unshift({
    department: user.ent_org.brandname,
    total: 0,
    complete: 0,
    inprogress: 0,
    pending: 0,
    other: 0,
  });

  const DeptPriorityColumns = [
    {
      label: "",
      attribute: "department",
      width: "10%",
      headerClassName: "txt-left",
      isLabel: true,
    },

    {
      label: "Max Aging<br/>(Days)",
      attribute: "max_aging",
      width: "8%",
      headerClassName: "bgDDFFFF",
    },
    {
      label: "Avg Aging<br/>(Days)",
      attribute: "avg_aging",
      width: "8%",
      headerClassName: "bgDDFFFF",
    },
    {
      label: "Total",
      attribute: "total",
      width: "8%",
      headerClassName: "bgDDFFFF",
    },
    {
      label: "Critical",
      attribute: "critical",
      width: "8%",
      headerClassName: "",
      colorIndicator: true,
    },
    {
      label: "High",
      attribute: "high",
      width: "8%",
      headerClassName: "",
      colorIndicator: true,
    },
    {
      label: "Medium",
      attribute: "medium",
      width: "8%",
      headerClassName: "",
      colorIndicator: true,
    },
    {
      label: "Low",
      attribute: "low",
      width: "8%",
      headerClassName: "",
      colorIndicator: true,
    },
    {
      label: "Minimal",
      attribute: "minimal",
      width: "8%",
      headerClassName: "",
      colorIndicator: true,
    },
  ];
  const [tableData, setTableData] = useState([...deptInitialRows]);
  useEffect(() => {
    dispatch(commonActions.fetchDepartments());
  }, []);
  const getDataFromServer = async (dateRange) => {
    let payload = {
      date_range_type: "last_365_days",
      start_date: "01-11-2023",
      end_date: "20-11-2024",
      stats_type: "dept_priority_stats",
    };
    if (dateRange && dateRange.id) {
      payload.date_range_type = dateRange.id;
    }
    try {
      dispatch(taskActions.fetchArcStats(payload));
    } catch (error) {
      console.error("Error fetching data from server:", error);
    }
  };
  useEffect(() => {
    if (arcStats?.dept_priority_list) {
      setResponseData(arcStats?.dept_priority_list);
    } else if (deptPriorityStats && Object.keys(deptPriorityStats).length > 0) {
      setResponseData(deptPriorityStats);
    } else {
      setResponseData({});
    }
  }, [arcStats, deptPriorityStats]);

  useEffect(() => {
    if (responseData && user && departments) {
      let tempTableData = getDataFromResponse(
        responseData,
        user,
        departments,
        deptPriorityTotalAvgAging
      );
      setTableData([...tempTableData]);
    }
  }, [responseData, user, departments]);

  const DeptTableID = "arc-dept-priority-table";

  return (
    <>
      <TableComponent
        title="Department-wise Priority Status"
        topBarField={Field}
        rows={tableData}
        Columns={DeptPriorityColumns}
        tableField={Field}
        tableID={DeptTableID}
        tableClassName="arc-stats-tbl"
        onDateChange={getDataFromServer}
      />
    </>
  );
};

const getDataFromResponse = (
  res,
  user,
  departments,
  deptPriorityTotalAvgAging
) => {
  if (res) {
    let dept_priority_compliance = [];
    const brandname = user.ent_org.brandname;

    let totalRow = {
      department: brandname,
      max_aging: 0,
      avg_aging: deptPriorityTotalAvgAging || 0,
      total: 0,
      critical: 0,
      high: 0,
      medium: 0,
      low: 0,
      minimal: 0,
    };

    let departmentMap = {};

    // Process each department
    for (const key in res) {
      let correctedKey = key.replace(/\s/g, "");
      const normalizedKey = correctedKey.toLowerCase();
      const priorityStatus = res[key];

      const total = parseInt(priorityStatus.total, 10) || 0;
      const critical = parseInt(priorityStatus.Critical, 10) || 0;
      const high = parseInt(priorityStatus.High, 10) || 0;
      const mediumVal = parseInt(priorityStatus.Medium, 10) || 0;
      const defaultVal = parseInt(priorityStatus.Default, 10) || 0;
      const low = parseInt(priorityStatus.Low, 10) || 0;
      const minimal = parseInt(priorityStatus.Minimal, 10) || 0;
      const medium = mediumVal + defaultVal;
      let maxAging = parseInt(priorityStatus.max_aging, 10) || 0;
      let avgAging = parseInt(priorityStatus.avg_aging, 10) || 0;

      // Apply conditions for each row
      if (avgAging < 0 || avgAging > maxAging) {
        avgAging = "na";
      }
      if (maxAging > 365) {
        maxAging = "na";
      }

      // Update or create department entry
      if (departmentMap[normalizedKey]) {
        departmentMap[normalizedKey].total += total;
        departmentMap[normalizedKey].critical += critical;
        departmentMap[normalizedKey].high += high;
        departmentMap[normalizedKey].medium += medium;
        departmentMap[normalizedKey].low += low;
        departmentMap[normalizedKey].minimal += minimal;

        // Update max_aging (ignore "na")
        if (
          departmentMap[normalizedKey].max_aging !== "na" &&
          maxAging !== "na"
        ) {
          departmentMap[normalizedKey].max_aging = Math.max(
            departmentMap[normalizedKey].max_aging,
            maxAging
          );
        } else {
          departmentMap[normalizedKey].max_aging = "na";
        }
        // Update avg_aging (keep as "na" if already "na")
        if (
          departmentMap[normalizedKey].avg_aging !== "na" &&
          avgAging !== "na"
        ) {
          departmentMap[normalizedKey].avg_aging = avgAging;
        } else {
          departmentMap[normalizedKey].avg_aging = "na";
        }
      } else {
        departmentMap[normalizedKey] = {
          department: correctedKey,
          total: total,
          critical: critical,
          high: high,
          medium: medium,
          low: low,
          minimal: minimal,
          max_aging: maxAging,
          avg_aging: avgAging,
        };
      }
    }

    // Convert departmentMap to an array
    dept_priority_compliance = Object.values(departmentMap);

    // Calculate totalRow values
    totalRow.total = dept_priority_compliance.reduce(
      (sum, department) => sum + department.total,
      0
    );
    totalRow.critical = dept_priority_compliance.reduce(
      (sum, department) => sum + department.critical,
      0
    );
    totalRow.high = dept_priority_compliance.reduce(
      (sum, department) => sum + department.high,
      0
    );
    totalRow.medium = dept_priority_compliance.reduce(
      (sum, department) => sum + department.medium,
      0
    );
    totalRow.low = dept_priority_compliance.reduce(
      (sum, department) => sum + department.low,
      0
    );
    totalRow.minimal = dept_priority_compliance.reduce(
      (sum, department) => sum + department.minimal,
      0
    );

    // Skip "na" for max_aging
    totalRow.max_aging = dept_priority_compliance
      .filter((department) => department.max_aging !== "na")
      .reduce((max, department) => Math.max(max, department.max_aging), 0);

    // // Skip "na" for avg_aging
    // const validAvgAgingValues = dept_priority_compliance.reduce(
    //   (values, department) => {
    //     if (department.avg_aging !== "na") {
    //       values.push(department.avg_aging);
    //     }
    //     return values;
    //   },
    //   []
    // );

    // totalRow.avg_aging =
    //   validAvgAgingValues.length > 0
    //     ? Math.round(
    //         validAvgAgingValues.reduce((sum, value) => sum + value, 0) /
    //           validAvgAgingValues.length
    //       )
    //     : "na";

    // Sort the array alphabetically
    dept_priority_compliance.sort((a, b) =>
      a.department.toLowerCase().localeCompare(b.department.toLowerCase())
    );

    // Add totalRow at the top
    dept_priority_compliance.unshift(totalRow);

    // Filter out rows where all values are zero (except department name)
    dept_priority_compliance = dept_priority_compliance.filter((row) => {
      return Object.entries(row).some(
        ([key, value]) => key !== "department" && value !== 0
      );
    });

    // Replace zeros with "-"
    dept_priority_compliance.forEach((row) => {
      Object.entries(row).forEach(([key, value]) => {
        if (value === 0 || value === "0") {
          row[key] = "-";
        }
      });
    });

    return dept_priority_compliance;
  } else {
    return [];
  }
};

// totalRow.max_aging = dept_priority_compliance.reduce(
//   (sum, department) => Math.max(sum, department.max_aging),
//   0
// );
// totalRow.avg_aging = Math.round(
//   dept_priority_compliance.reduce(
//     (sum, department) => sum + department.avg_aging,
//     0
//   ) / departments?.length
// );
